"use client";
import { SeriesSubscriptionHeaderContent } from "@/components/SeriesSubscriptionHeaderContent";
import { SubscriptionHeaderContent } from "@/components/SubscriptionHeaderContent";
import React, { useState, type JSX } from "react";
import { ContentData } from "@/cms/types";
import { ProductVariantList } from "@/cms/types/objects";
import { getThemeClassName } from "@/theming/selectors";
import classNames from "classnames";
import { getCSSPropertiesTheme } from "@/theming/getCSSProperties";
import { getPageLink } from "@/navigation/selectors";
import { SubscriptionProduct } from "@cappelendamm/e-commerce-services";
import Link from "next/link";
import { ValuePropositionListItems } from "@/portable-text/ValuePropositionList.tsx/ValuePropositionList";
import { ExpandableText } from "@/components/ExpandableText";
import { SubscriptionFocusSectionQueryResult } from "@/cms/sanity.types";
import { SanityImage } from "@/cms/images/types";
import { Section } from "@/components/Section";

type SubscriptionCampaignFocusProps = ContentData<
  NonNullable<SubscriptionFocusSectionQueryResult>
> & {
  subscriptionProduct: SubscriptionProduct;
  authorName?: string | null;
} & { index: number };

const getSelectorItemsFromCampaignBooks = (
  books?:
    | NonNullable<
        NonNullable<SubscriptionFocusSectionQueryResult>["subscription"]
      >["books"]
    | null,
): ProductVariantList => {
  const productVariants =
    books?.map((book, index) => {
      const subTitle = book.name;
      const title = `BOK ${(index += 1)}`;

      return {
        key: book.sku ?? "",
        title: title ?? "",
        subTitle: subTitle ?? "",
        image: book.image as SanityImage,
        slug: book.slug?.current,
      };
    }) ?? [];
  return { items: productVariants };
};

export const SubscriptionCampaignFocus = ({
  data: {
    _id,
    subscription,
    description,
    price,
    valuePropositionList,
    theme,
    buttonTheme,
    hideAuthor,
    internalTitle,
    ...content
  },
  subscriptionProduct,
  authorName,
  index,
  children,
}: SubscriptionCampaignFocusProps &
  Omit<
    JSX.IntrinsicElements["section"],
    keyof SubscriptionCampaignFocusProps
  >) => {
  const booksList = getSelectorItemsFromCampaignBooks(subscription?.books);
  const [startBookItem, setStartBookItem] = useState(booksList.items[0]);

  if (!subscription) return null;

  const subHeader = authorName
    ? `Serie av ${authorName}`
    : subscriptionProduct.plan.name;

  const href = getPageLink({
    pageType: "checkout",
    params: subscriptionProduct.sku,
    searchParams: subscriptionProduct.isSeries
      ? { initialSku: startBookItem.key }
      : {},
  });

  return (
    <Section
      trackingData={{
        ui: {
          item_id: _id ?? "section-id-missing",
          item_name:
            internalTitle?.replaceAll(" ", "-") ?? "section-title-missing",
          item_type: "subscription-campaign-focus-section",
          index: index,
        },
      }}
      className={classNames(
        getThemeClassName(content.styleVariant === "color" ? "primary" : null),
        "bg-body text-body-contrast relative overflow-hidden pt-6",
      )}
      style={{
        ...(content.styleVariant === "color"
          ? getCSSPropertiesTheme(theme)
          : {}),
      }}
    >
      <div className="app-grid relative mx-auto overflow-hidden px-3 lg:overflow-visible! lg:px-0">
        <div className="col-span-6 mb-6 flex flex-col sm:col-span-12">
          <h1 className="heading-01 leading-[1.2]">
            {subscriptionProduct.name}
          </h1>
          {hideAuthor ? null : <span>{subHeader}</span>}
        </div>

        {children && (
          <div
            className={classNames(
              "relative col-span-full aspect-4/3 w-full self-start sm:col-span-3 md:col-start-1 md:col-end-7",
            )}
          >
            <Link href={href}>{children}</Link>
          </div>
        )}
        <div
          className={classNames(
            "py-content sm:pr-content col-span-full sm:col-span-3 md:col-span-6 md:ml-12 lg:py-0",
          )}
        >
          <div>
            {description && (
              <div className="prose">
                <ExpandableText text={description} maxLines={5} />
              </div>
            )}
            {subscriptionProduct?.isSeries ? (
              <SeriesSubscriptionHeaderContent
                price={price}
                buttonTheme={buttonTheme}
                styleVariant={content.styleVariant}
                subscription={subscriptionProduct}
                booksList={booksList}
                startBookItem={startBookItem}
                onChange={setStartBookItem}
              />
            ) : (
              <SubscriptionHeaderContent
                buttonTheme={buttonTheme}
                styleVariant={content.styleVariant}
                subscription={subscriptionProduct}
              />
            )}
            {valuePropositionList?.items ? (
              <ValuePropositionListItems
                className="flex-col gap-3! pt-4"
                items={valuePropositionList.items}
              />
            ) : null}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default SubscriptionCampaignFocus;
