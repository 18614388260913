import { SanityImage } from "@/cms/images/types";
import { PortableTextValue } from "@/portable-text/PortableText";
import { BookContent } from "@/products/types";
import { SanityDocument } from "next-sanity";

export enum ContributorType {
  AUTHOR = "Forfatter",
  TRANSLATOR = "Oversetter",
  NARRATOR = "Oppleser",
  ILLUSTRATOR = "Illustratør",
}

export type ContributorLink = {
  urlText?: string;
  url?: string;
  _type: "contributorLink";
  _key: string;
};

export type Contributor = {
  name: string;
  image: SanityImage;
  biography: PortableTextValue;
  slug: string;
  contributorLinks: ContributorLink[];
  yearOfBirth: number;
  yearOfDeath?: number;
  contributorType: ContributorType | string;
} & Pick<SanityDocument, "_id" | "_type">;

export type AuthorBooks = BookContent;
