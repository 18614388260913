"use client";

import React, { useRef, useState, useEffect, useId } from "react";
import { PortableText, PortableTextValue } from "@/portable-text/PortableText";

interface ExpandableTextProps {
  text: PortableTextValue;
  maxParagraphs?: number;
  maxLines?: number;
}

export function ExpandableText({
  text,
  maxParagraphs = 2,
  maxLines = 10,
}: ExpandableTextProps) {
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [isLineTruncated, setIsLineTruncated] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  const id = useId();
  const buttonId = `toggle-button-${id}`;
  const contentId = `expandable-content-${id}`;

  const blocks = Array.isArray(text) ? text : [text];
  const collapsedContent = blocks.slice(0, maxParagraphs);
  const isParagraphTruncated = blocks.length > maxParagraphs;

  useEffect(() => {
    if (textRef.current && !isShowingMore) {
      const isOverflowing =
        textRef.current.scrollHeight > textRef.current.clientHeight;
      setIsLineTruncated(isOverflowing);
    }
  }, [textRef, isShowingMore]);

  const handleToggle = () => {
    if (isShowingMore) {
      containerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    setIsShowingMore(!isShowingMore);
  };

  return (
    <div ref={containerRef}>
      <div
        ref={textRef}
        id={contentId}
        className="prose [&>*+*]:mb-4"
        style={{
          display: isShowingMore ? "block" : "-webkit-box",
          WebkitLineClamp: isShowingMore ? "unset" : maxLines,
          WebkitBoxOrient: "vertical",
          overflow: isShowingMore ? "visible" : "hidden",
        }}
        role="region"
        aria-labelledby={buttonId}
      >
        <PortableText value={isShowingMore ? blocks : collapsedContent} />
      </div>

      {(isParagraphTruncated || isLineTruncated) && (
        <button
          id={buttonId}
          aria-expanded={isShowingMore}
          aria-controls={contentId}
          onClick={handleToggle}
          className="mt-2 font-semibold text-[#9A4234] underline decoration-[#9A4234]"
        >
          {isShowingMore ? "Les mindre" : "Les mer"}
        </button>
      )}
    </div>
  );
}
