import { UITrackData } from "@/tracking/ui-event";
import { getTrackingDataset } from "@/tracking/utils";
import { type JSX } from "react";

export type SectionProps = {
  trackingData: UITrackData;
};

export function Section({
  children,
  trackingData,
  ...rest
}: SectionProps & Omit<JSX.IntrinsicElements["section"], keyof SectionProps>) {
  const trackingProps = getTrackingDataset(trackingData);
  return (
    <section {...trackingProps} {...rest}>
      {children}
    </section>
  );
}
